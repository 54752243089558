<template>
  <div class="add-science">
    <el-button type="text" class="back-btn" @click="$router.go(-1)">
      <i class="el-icon-arrow-left"></i> 返回
    </el-button>
    <el-form
      :model="ruleForm"
      :rules="rules"
      ref="ruleForm"
      label-width="120px"
      class="demo-ruleForm"
    >
      <el-form-item label="科技资源标题" prop="title">
        <el-input class="com-long" v-model="ruleForm.title" placeholder="请输入文章标题"></el-input>
      </el-form-item>
      <el-form-item label="科技资源主图" prop="image">
        <el-upload
          class="avatar-uploader"
          :action="URL.upLoad"
          :show-file-list="false"
          :on-success="uploadSuccess"
          :before-upload="beforeUpload"
          accept="image/jpeg, image/png, image/jpg"
        >
          <img v-if="ruleForm.image" :src="ruleForm.image" class="avatar" />
          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
        </el-upload>
        <span class="image-tag">只支持jpg、jpeg、png格式，大小不超过2M，建议尺寸：200*200</span>
      </el-form-item>

      <el-form-item label="所属类别" prop="tags">
        <el-checkbox-group v-model="ruleForm.tags">
          <el-checkbox v-for="(item,i) in techCategories" :label="item.id" :key="i">{{item.name}}</el-checkbox>
        </el-checkbox-group>
        <el-button type="primary" @click="orderClick">自定义</el-button>
      </el-form-item>
      <el-form-item label="一句话摘要" prop="intro">
        <el-input class="com-long" v-model="ruleForm.intro" placeholder="请输入一句话摘要"></el-input>
      </el-form-item>
      <el-form-item label="关键词">
        <div class="keyword">
          <el-form-item prop="keyword1">
            <el-input v-model="ruleForm.keyword1"></el-input>
          </el-form-item>
          <el-form-item prop="keyword2">
            <el-input v-model="ruleForm.keyword2"></el-input>
          </el-form-item>
          <el-form-item prop="keyword3">
            <el-input v-model="ruleForm.keyword3"></el-input>
          </el-form-item>
          <el-form-item prop="keyword4">
            <el-input v-model="ruleForm.keyword4"></el-input>
          </el-form-item>
        </div>
      </el-form-item>
      <el-form-item label="科技资源来源" prop="from">
        <el-input class="com-long" v-model="ruleForm.from" placeholder="请输入资源来源（如：绵阳政务网）"></el-input>
      </el-form-item>

      <el-form-item label="科技资源内容" prop="main_content">
        <Editor class="com-long" @getContent="getEditorContent" :value="ruleForm.main_content"></Editor>
      </el-form-item>
      <el-form-item>
        <el-button type="danger" @click="$router.go(-1)">取消</el-button>
        <el-button type="primary" @click="save('ruleForm')">保存</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import Editor from "@/components/Editor/index";
import { success, warning, error } from "@/utils/notification";
export default {
  name: "addScience",
  components: { Editor },
  data() {
    return {
      cateID: 10000, //自定义分类id
      ruleForm: {
        keyword1: "",
        keyword2: "",
        keyword3: "",
        keyword4: "",
        organ_id: 0, //	机构ID
        title: "", //标题
        image: "", //主图
        intro: "", //简介
        keyword: [],
        from: "", //来源
        main_content: "", //科技资源内容
        tags: [] //分类
      },
      techCategories: [], //所属类别数据
      rules: {
        keyword1: [{ max: 8, message: "不超过8个字符", trigger: "blur" }],
        keyword2: [{ max: 8, message: "不超过8个字符", trigger: "blur" }],
        keyword3: [{ max: 8, message: "不超过8个字符", trigger: "blur" }],
        keyword4: [{ max: 8, message: "不超过8个字符", trigger: "blur" }],
        title: [
          { required: true, message: "请输入科技资源标题", trigger: "blur" },
          { max: 60, message: "不超过60个字符", trigger: "blur" }
        ],
        image: [{ required: true, message: "请上传资源图片", trigger: "blur" }],
        intro: [
          { required: true, message: "请输入一句话摘要", trigger: "blur" },
          { max: 36, message: "不超过36个字符", trigger: "blur" }
        ],
        from: [
          { required: true, message: "请输入资源来源", trigger: "blur" },
          { max: 36, message: "不超过36个字符", trigger: "blur" }
        ],
        main_content: [
          { required: true, message: "请输入资源内容", trigger: "blur" }
        ],
        tags: [
          {
            type: "array",
            required: true,
            message: "请至少选择一个所属类别",
            trigger: "change"
          }
        ]
      }
    };
  },
  created() {
    this.ruleForm.organ_id = sessionStorage.organizationId;
    this.getTechCategory(this.ruleForm.organ_id);
    this.$route.query.id &&
      this.getDetails(this.$route.query.id, this.ruleForm.organ_id);
  },
  methods: {
    //保存
    save(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.ruleForm.tags = this.ruleForm.tags.map(item => {
            if (item < 10000) {
              item = { id: item };
            } else {
              this.techCategories.forEach(item2 => {
                if (item == item2.id) {
                  item = { id: 0, name: item2.name };
                }
              });
            }
            return item;
          });
          if (
            this.ruleForm.keyword1.trim() != "" ||
            this.ruleForm.keyword2.trim() != "" ||
            this.ruleForm.keyword3.trim() != "" ||
            this.ruleForm.keyword4.trim() != ""
          ) {
            this.ruleForm.keyword = [
              this.ruleForm.keyword1,
              this.ruleForm.keyword2,
              this.ruleForm.keyword3,
              this.ruleForm.keyword4
            ];
            if (this.$route.query.id) {
              this.edit(this.$route.query.id);
            } else {
              this.add();
            }
          } else {
            warning("请至少填写一个关键词");
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    //获取富文本内容
    getEditorContent(e) {
      this.ruleForm.main_content = e;
    },
    //上传图片前
    beforeUpload(file) {
      console.log(file, "上传图片前");
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        warning("上传图片大小不能超过 2MB!");
      }
      return isLt2M;
    },
    //图片上传成功
    uploadSuccess(e) {
      console.log(e, "22");
      this.ruleForm.image = e.data.path;
      success("图片上传成功");
    },
    //科技资源分类
    async getTechCategory(organ_id) {
      const { data: res } = await this.$http.get(
        this.URL.adminUrl.techCategory,
        {
          params: { organ_id }
        }
      );
      this.techCategories = res.data;
      console.log(res.data, "科研仪器分类");
    },
    //自定义分类
    orderClick() {
      this.$prompt("请输入自定义类别", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消"
      })
        .then(({ value }) => {
          if (value.trim()) {
            this.cateID++;
            this.techCategories.push({ id: this.cateID, name: value });
          } else {
            warning("未填写内容");
          }
        })
        .catch(() => {});
    },
    //获取科技资源详情
    async getDetails(id, organ_id) {
      const { data: res } = await this.$http.get(
        this.URL.adminUrl.resource + id,
        {
          params: { organ_id }
        }
      );
      let obj = res.data;
      this.ruleForm = Object.assign(this.ruleForm, obj);

      this.ruleForm.tags = this.ruleForm.category.map(item => item.id);
      this.ruleForm.keyword.forEach((item, i) => {
        if (item) {
          this.ruleForm["keyword" + (i + 1)] = this.ruleForm.keyword[i];
        }
      });
    },
    //添加科技资源
    async add() {
      const { data: res } = await this.$http.post(
        this.URL.adminUrl.resource,
        this.ruleForm
      );
      success("科技资源信息添加成功");
      this.$router.push("/sendScience");
      console.log(res, "保存保存");
    },
    //编辑科技资源
    async edit(id) {
      delete this.ruleForm.category;
      const { data: res } = await this.$http.put(
        this.URL.adminUrl.resource + id,
        this.ruleForm
      );
      success("科技资源信息编辑成功");
      this.$router.push("/sendScience");
    }
  }
};
</script>
<style lang="less" scoped>
.add-science {
  padding: 20px 5px;
  .back-btn {
    margin: 0 20px 40px;
    font-size: 18px;
  }

  .com-long {
    width: 50%;
  }

  .image-tag {
    position: absolute;
    left: 160px;
    top: 10px;
    width: 150px;
    font-size: 13px;
    color: rgb(163, 161, 157);
  }
  .keyword {
    display: flex;
  }
  .el-form-item__label::before {
    content: "*";
    color: #f56c6c;
    margin-right: 4px;
  }
}
</style>
<style >
/* 上传图片样式 */
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 148px;
  height: 148px;
  line-height: 148px;
  text-align: center;
}
.avatar {
  width: 148px;
  height: 148px;
  display: block;
}

.add-science .el-form-item__label::before {
  content: "*";
  color: #f56c6c;
  margin-right: 4px;
}
</style>